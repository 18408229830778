<template>
  <b-overlay
      :show="overlay"
      rounded="sm"
  >
    <div v-if="channels !== null">

      <!-- Table Container Card -->
      <b-card
          no-body
          class="mb-0"
      >

        <div class="m-2">

          <!-- Table Top -->
          <b-row>

            <b-col
                cols="12"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <b-button
                  variant="primary"
                  @click="addNewCategory = !addNewCategory"
              >
                <span class="text-nowrap">+ ساخت کانال جدید</span>
              </b-button>
            </b-col>
            <!--   new Category name  -->
            <transition name="fade">
              <b-col
                  v-if="addNewCategory"
                  cols="12"
                  class="d-flex align-items-center justify-content-start mb-1 mb-md-0 mt-1 px-0"
              >
                <b-col md="8">
                  <div class="form-label-group">
                    <b-form-input
                        id="name"
                        placeholder="نام کانال"
                        v-model="newChannelTitle"
                    />
                  </div>
                </b-col>
                <b-col md="2" class="mb-1">
                  <b-button
                      @click="createChannel"
                      variant="success"
                  >
                    <span class="text-nowrap">ساخت</span>
                  </b-button>
                </b-col>
              </b-col>
            </transition>
          </b-row>

        </div>

        <b-table
            ref="refUserListTable"
            class="position-relative"
            :items="channels"
            responsive
            :fields="myTableColumns"
            primary-key="id"
            show-empty
            bordered
            striped
            empty-text="موردی موجود نیست!"
        >

          <!-- Column: createDate -->

          <template #cell(createDate)="data">

            <p>
              {{data.item.createDate.slice(11,16)}}
              -
              {{createJalaliDate(data.item.createDate.slice(0,10))}}
            </p>

          </template>

          <!-- Column: delete -->

          <template #cell(delete)="data">

            <div class="cursor-pointer d-flex flex-row"
                 v-b-modal.modal-delete
                 @click="showModal(data.item.channelId)"
            >
              <feather-icon size="20" icon="TrashIcon" class="text-danger" />
            </div>

          </template>

          <template #cell(edit)="data">

            <router-link :to="{ name: 'apps-channel-edit', params: { id: data.item.channelId } }" class="cursor-pointer d-flex flex-row">
              <feather-icon size="20" icon="EditIcon" />
            </router-link>

          </template>

          <template #cell(show)="data">

            <router-link :to="{ name: 'apps-channel-id', params: { id: data.item.channelId } }" class="cursor-pointer d-flex flex-row">
              <feather-icon size="20" icon="CastIcon" />
            </router-link>

          </template>

        </b-table>
        <div class="mx-2 mb-2">
          <b-row>

            <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
            </b-col>
          </b-row>
        </div>
      </b-card>
    </div>
    <b-modal
        id="modal-delete"
        centered
        ok-title="حذف"
        cancelTitle="انصراف"
        @ok="deleteChannel(deleteItem)"
    >
      <span>حذف شود؟</span>
    </b-modal>
  </b-overlay>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,BOverlay,BModal,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {ChannelGetAllRequest,ChannelDeleteRequest,ChannelCreateRequest,ChannelGetRequest} from "@/libs/Api/Channel";

export default {
  name: "ChannelList",
  title:"لیست کانال ها - پنل ادمین مکس ",
  data(){
    return{
      channels:null,
      overlay:false,
      totalCount:null,
      addNewCategory:false,
      deleteItem:null,
      currentPage:1,
      perPage:10,
      myTableColumns : [
        { key: 'title',label:'عنوان'},
        { key: 'createDate',label:'تاریخ ثبت'},
        { key: 'delete',label:'حذف'},
        { key: 'edit',label:'ویرایش'},
        { key: 'show',label:'نمایش کانال'},
      ],
      newChannelTitle:'',
    }
  },
  async created(){
    await this.getChannels()
  },
  methods:{
    async getChannels(){
      let _this = this;
      _this.overlay = true;
      let data = {
        pageNumber:0,
        count:0,
        searchCommand:''
      }

      let channelGetAllRequest = new ChannelGetAllRequest(_this);
      channelGetAllRequest.setParams(data);
      await channelGetAllRequest.fetch(function (content){
        _this.overlay = false;
        _this.channels = content.channels;
        _this.totalCount = content.channelsCount;
      } , function (content){
        _this.overlay = false;
        console.log(content)
      });
    },
    async createChannel(){
      let _this = this;
      _this.overlay = true;
      let data={
       isDeleted: false,
       channelId: 0,
       title: _this.newChannelTitle,
       createDate: new Date(),
      }

      let channelCreateRequest = new ChannelCreateRequest(_this);
      channelCreateRequest.setParams(data);
      await channelCreateRequest.fetch(function (content){
        _this.overlay = false;
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `عملیات موفق`,
            icon: 'CheckIcon',
            variant: 'success',
            text: `کانال جدید اضافه شد.`,
          },
        })
        _this.getChannels()
      },function (error){
        _this.overlay = false;
        console.log(error)
      })
    },
    async deleteChannel(param){
      let _this = this;
      _this.overlay = true;

      let channelDeleteRequest = new ChannelDeleteRequest(_this);
      channelDeleteRequest.setId(param);
      await channelDeleteRequest.fetch(function (content){
        _this.overlay = false;
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `عملیات موفق`,
            icon: 'CheckIcon',
            variant: 'success',
            text: `کانال حذف شد.`,
          },
        })
        _this.getChannels()
      },function (error){
        _this.overlay = false;
        console.log(error)
      })
    },
    showModal(param){
      let _this = this;
      _this.deleteItem = param
    },
    createJalaliDate(param){
      return new Date(param).toLocaleDateString("fa-IR",);
    },
  },
  components:{

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BOverlay,
    BModal,

    vSelect,
  },
}
</script>

<style scoped>

</style>